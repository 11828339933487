import React from "react";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import Banner from "../components/SecureITSolutions/Banner";
import OurSolutions from "../components/SecureITSolutions/OurSolutions";
import OurServices from "../components/SecureITSolutions/OurServices";
import OurFeatures from "../components/SecureITSolutions/OurFeatures";
import TeamMember from "../components/SecureITSolutions/TeamMember";
import RecentProjects from "../components/SecureITSolutions/RecentProjects";
import Pricing from "../components/SecureITSolutions/Pricing";
import Testimonials from "../components/SecureITSolutions/Testimonials";
import Partner from "../components/SecureITSolutions/Partner";
import ProjectStartArea from "../components/SecureITSolutions/ProjectStartArea";
import OurBlog from "../components/SecureITSolutions/OurBlog";
import { graphql } from "gatsby";
import { Link, useI18next } from "gatsby-plugin-react-i18next";


const Home = ({ pageContext, data }) => {
  const { language } = pageContext;
  return (<Layout>
    <Navbar />

    <Banner data={data} />

    <OurSolutions data={data} />

    <OurServices data={data} />

    <OurFeatures data={data} />

    {/*<TeamMember data={data} />*/}

    {/*<RecentProjects />*/}

    {/*<Pricing />*/}

    {/*<Testimonials data={data} />*/}

    {/*<Partner />*/}

    {/*<OurBlog />*/}

    <ProjectStartArea data={data} />

    <Footer />
  </Layout>);
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "SecureITSolutions"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
   strapiDefaultBanner(locale: {eq: $language}) {
    header
    helpText
    btnText
    btnLink
    image {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
  
   strapiSolution(locale: {eq: $language}) {
        simpleText
        headerText
        helpText
    }
    allStrapiServiceSolution(filter: {locale: {eq: $language}}) {
        nodes {
            icon
            title
            shortText
            slug
        }
    }
    
   strapiServicesOne(locale: {eq: $language}) {
    simpleText
    header
    helpText
    image {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    services {
      id
      icon
      services
    }
  }
  
  strapiServicesTwo(locale: {eq: $language}) {
    simpleText
    header
    helpText
    image {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    services {
      id
      icon
      services
    }
  }
  
  strapiOurFeatures(locale: {eq: $language}) {
    simpleText
    header
    helpText
    features {
      id
      title
      desc
      icon {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
  
   strapiTeamStyle1(locale: {eq: $language}) {
    subTitle
    title
    shortText
    teamCard {
      id
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      name
      designation
      socialLinks {
        id
        icon
        link
      }
    }
  }
  
  strapiStartYourProject(locale: {eq: $language}) {
    images {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    title
    shortText
    btnText
    btnLink
  }
  
    strapiTestimonials(locale: {eq: $language}) {
    subTitle
    title
    shortText
    item {
      id
      name
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      feedbackText
      designation
    }
  }
  }
`;
